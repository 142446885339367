import { render, staticRenderFns } from "./wylx.vue?vue&type=template&id=c948b3ba&scoped=true&"
import script from "./wylx.vue?vue&type=script&lang=js&"
export * from "./wylx.vue?vue&type=script&lang=js&"
import style0 from "./wylx.vue?vue&type=style&index=0&id=c948b3ba&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c948b3ba",
  null
  
)

export default component.exports